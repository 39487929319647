@mixin theme (
    $border,
    $thead-bg,
    $tbody-bg,
    $active-bg,
    $active-border,
    $checkbox-bg,
    $delete-bg,
    $delete-border
) {
    dev-grid, dev-treetable, big-list  {
        /* position: relative; */
        .dev-table {
            padding: 0 1px;
        }
        .table-bordered {
            border: none;
        }
        .table-bordered tr {
            border: 1px solid $border;
        }
        thead tr {
            background-color: $thead-bg;
        }
        tbody > tr.selected,
        tbody > tr.checked {
            background-color: $active-bg !important;
            border: solid 2px $active-border !important;
            line-height: 32px;
        }
        tbody > tr.deleted {
            background-color: $delete-bg !important;
            border: solid 2px $delete-border !important;
            line-height: 32px;
            text-decoration: line-through;
        }
        th, td {
            line-height: 24px !important;
            border-bottom: none !important;
            padding: 8px 16px !important;
        }
        td, tbody td:first-of-type {
            // display: flex;
            // height: 24px !important;
            line-height: 24px !important;
            vertical-align: middle !important;
            padding: 8px !important;
            align-items: center;
        }
        // thead
        // tbody
        .table-striped > tbody > tr:nth-child(odd) {
            background-color: $tbody-bg;
        }
        .table .is-icon {
            width: calc(100% - 15px);
        }
        .width100 {
            width: 100%;
        }
    }
    dev-nested-grid {
        thead tr {
            background-color: $nested-thead-bg;
        }
    }
}

.dev-table {
    .table-btn-group {
        position: relative;
    }
    .table-btn-group button {
        margin-right: 4px;
    }
}

.dev-table.loading {
    pointer-events: none;
    opacity: 0.5;
}

.dev-table.repeater-container {
	max-height: 900px;
    overflow: auto;
}

.dev-table .spin {
    text-align: center;
    position: absolute;
    left: 50%;
    top: 35px;
    z-index: 999;
}

.cell-tree {
    position: relative;
}

.cell-tree .badge {
    position: absolute;
    right: 0px;
}

.dev-table .bordered > thead > tr > th,
.dev-table .bordered > tbody > tr > th,
.dev-table .bordered > tfoot > tr > th,
.dev-table .bordered > thead > tr > td,
.dev-table .bordered > tbody > tr > td,
.dev-table .bordered > tfoot > tr > td {
    border: 1px solid #ebeef1;
}

.dev-table .text-edit {
    input {
        display: inline;
        width: 150px;
        height: 27px;
        line-height: 27px;
    }
}

.dev-table .dev-nested-table {
    td input {
        height: 27px;
        max-width: 100px;
    }
}


@import '../../common/styles/variables';

.dev-colorpicker {
    .dev-colorpicker-row-split {
        color: $cyan-plain;

        hr {
            border-top: 1px solid $cyan-plain;
        }
    }
}

// basic colors
$primary-normal: #0071d0;
$primary-hover: #1e76e2;
$primary-active: #0062b5;

$info-normal: #20b3f3;
$info-hover: #2cbdfc;
$info-active: #0096d7;

$success-normal: #1daf46;
$success-hover: #2fc058;
$success-active: #07972f;

$default-normal: #c7c7c7;
$default-hover: #dbdbdb;
$default-active: #dbdbdb;

$warning-normal: #eda802;
$warning-hover: #feb405;
$warning-active: #e09e01;

$danger-normal: #f65657;
$danger-hover: #f96767;
$danger-active: #dd3939;

$link-normal: #0061e4;
$link-hover: #1fb3f3;
$link-active: #1fb3f3;

$disabled: #9f9f9f;


// blue theme
$blue-text: #000000;
$blue-title: #253645;
$blue-plain: #34495e;
$blue-link: #77b2ed;
$blue-hover: #2c7fd3;
$blue-bg: #1fb3f3;

// blue left nav
$blue-leftnav-background: #34495e;
$blue-left-topnav-active: #253645;
$blue-topnav-open-background: #3c536a;
$blue-leftnav-border: #20b3f3;

// blue content
$blue-division: #e3e9f6;
$blue-question: #e6efff;
$blue-question-color: #543f3f;
$blue-answer: #ddf3e6;
$blue-content-font-background: #f1f5fb;
$blue-code-background: #ffffed;
$blue-code-border: #f0dfcb;
$blue-datetime-border: #c7d1dd;
$blue-content-active-background: #1fb3f3;
$blue-content-active: #ffffff;
$blue-progress-notdone: #c8d8e7;
$blue-button-green: #1dae45;
$blue-depth-shade: #04141f;
$blue-depth-opacity: 0.6;
$blue-button-border: #1ab394;

// blue table
$blue-thead-background: #dde6ef;
$blue-table-border: #d4dde4;
$blue-table-interval: #fafbfd;
$blue-table-tr-hover: #f6f7f7;
$blue-tr-active-background: #e9f0fa;
$blue-tr-active-border: #93dcff;
$blue-tr-success-background: #dcfef0;
$blue-tr-success-border: #41cc93;
$blue-tr-disabled-background: #ffefef;
$blue-tr-disabled-border: #ff7573;
$blue-tr-unkown-font: #a5b5c5;
$blue-tr-error-font: #ff7573;
$blue-tr-success-font: #42cd94;
$blue-checkbox-bg: #1db4fb;     //表格checkbox边框
$blue-page-button: #ffffff;
$blue-page-border: #d8dfe7;
$blue-page-active: #41678c;
$blue-table-success-progress: #88d472;
$blue-table-success-progress-font: #2c5e1f;
$blue-table-error-progress: #f86e7d;
$blue-table-error-progress-font: #88141d;


// orange
$orange-text: #000000;
$orange-title: #253645;  //标题文字
$orange-plain: #4e555b;  //一般文字
$orange-link: #e38002;   //link文字
$orange-hover: #f69921;  //link hover文字
$orange-bg: #f69920; //主色调背景

// orange left nav
$orange-leftnav-background: #828a8d;   //左侧导航栏背景色
$orange-left-topnav-active: #4e555b;    //左侧导航栏一级目录选中底色
$orange-topnav-open-background: #969c9e; //一级目录展开后二级背景色
$orange-leftnav-border: #f39c11;     //左侧导航栏二级目录选中样式

// orange content
$orange-division: #eceeee;      //内容区分割线
$orange-question: #fff5ec;      //问答对话中【问】的文字背景色
$orange-question-color: #2f2619;      //问答对话中【问】的文字背景色
$orange-question-border: #f5eae0;      //问答对话中【问】的文字border
$orange-answer: #ebf2f7;         //问答对话中【答】得文字背景色
$orange-answer-border: #e6eaee;        //问答对话中【答】的文字border
$orange-content-font-background: #f1f5fb; //一般文字块背景色
$orange-code-background: #ffffed;      //代码块背景色
$orange-code-border: #f0dfcb;          //代码块边框
$orange-nest-title: #1bbbb9;           //多层嵌套符号标题
$orange-datetime-border: #f2e7d9;      //时间选择器描边
$orange-content-active-background: #f8a423;       //时间选择器下拉框选中
$orange-content-active: #ffffff;       //时间选择器下拉框选中
$orange-progress-notdone: #c8d8e7;     //分布引导未完成进度条
$orange-button-green: #1dae45;         //绿色按钮
$orange-depth-shade: #000000;
$orange-depth-opacity: 0.6;
$orange-button-border: #f39c11;

// orange table
$orange-thead-background: #f2f2f2;    //表格表头底色
$orange-table-border: #dfdfdf;        //表格分割线、border
$orange-table-interval: #fafafa;      //表格间隔行颜色
$orange-tr-active-background: #e9f0fa; //表格行选中
$orange-tr-active-border: #93dcff;     //表格行border
$orange-tr-success-background: #dcfef0; //表格行通过
$orange-tr-success-border: #41cc93;
$orange-tr-disabled-background: #ffefef; //表格行禁用
$orange-tr-disabled-border: #ff7573;
$orange-tr-unkown-font: #a5b5c5;       //表格【未知】状态文字背景
$orange-tr-error-font: #ff7573;        //表格【异常】状态文字背景
$orange-tr-success-font: #42cd94;      //表格【正常】状态文字背景
$orange-checkbox-bg: #f69920;     //表格checkbox边框
$orange-page-button: #ffffff;         //表格分页按钮正常状态
$orange-page-border: #d8dfe7;         //表格分页按钮border
$orange-page-active: #e5ddd0;         //表格分页按钮按下状态
$orange-table-success-progress: #88d472;   //表格正常显示进度条颜色
$orange-table-success-progress-font: #2c5e1f;  //正常进度条上方文字颜色
$orange-table-error-progress: #f86e7d;     //表格异常显示进度条颜色
$orange-table-error-progress-font: #88141d; //异常进度条上方文字颜色


//cyan   蓝青色
$cyan-text: #000000;
$cyan-title: #0d3c5d;  //标题文字
$cyan-plain: #355373;  //一般文字
$cyan-link: #0061e5;   //link文字
$cyan-hover: #20b3f3;  //link hover文字
$cyan-bg: #1fb3f3;
$cyan-wrapper-bg: #f2f5f8;

// cyan left nav
$cyan-leftnav-background: #355473;   //左侧导航栏背景色
$cyan-left-topnav-active: #0e3c5d;    //左侧导航栏一级目录选中底色
$cyan-topnav-open-background: #416690; //一级目录展开后二级背景色
$cyan-leftnav-border: #20b3f3;     //左侧导航栏二级目录选中样式

// cyan content
$cyan-division: #e3e9f6;      //内容区分割线
$cyan-question: #e6efff;      //问答对话中【问】的文字背景色
$cyan-question-color: #543f3f;      //问答对话中【问】的文字背景色
$cyan-answer: #ddf3e6;         //问答对话中【答】得文字背景色
$cyan-content-font-background: #f1f5fb; //一般文字块背景色
$cyan-code-background: #ffffed;      //代码块背景色
$cyan-code-border: #f0dfcb;          //代码块边框
$cyan-nest-title: #34bd61;           //多层嵌套符号标题
$cyan-datetime-border: #c7d1dd;      //时间选择器描边
$cyan-content-active-background: #1fb3f3;       //时间选择器下拉框选中
$cyan-content-active: #ffffff;       //时间选择器下拉框选中
$cyan-progress-notdone: #c8d8e7;     //分布引导未完成进度条
$cyan-button-green: #1dae45;         //绿色按钮
$cyan-depth-shade: #04141f;
$cyan-depth-opacity: 0.6;
$cyan-button-border: #1763b9;

// cyan table
$cyan-thead-background: #dde6ef;    //表格表头底色
$cyan-table-border: #d4dde4;        //表格分割线、border
$cyan-table-interval: #fafbfd;      //表格间隔行颜色
$cyan-tr-active-background: #e9f0fa; //表格行选中
$cyan-tr-active-border: #93dcff;     //表格行border
$cyan-tr-success-background: #dcfef0; //表格行通过
$cyan-tr-success-border: #41cc93;
$cyan-tr-disabled-background: #ffefef; //表格行禁用
$cyan-tr-disabled-border: #ff7573;
$cyan-tr-unkown-font: #a5b5c5;       //表格【未知】状态文字背景
$cyan-tr-error-font: #ff7573;        //表格【异常】状态文字背景
$cyan-tr-success-font: #42cd94;      //表格【正常】状态文字背景
$cyan-checkbox-bg: #1db4fb;     //表格checkbox边框
$cyan-page-button: #ffffff;         //表格分页按钮正常状态
$cyan-page-border: #d8dfe7;         //表格分页按钮border
$cyan-page-active: #41678c;         //表格分页按钮按下状态
$cyan-table-success-progress: #88d472;   //表格正常显示进度条颜色
$cyan-table-success-progress-font: #2c5e1f;  //正常进度条上方文字颜色
$cyan-table-error-progress: #f86e7d;     //表格异常显示进度条颜色
$cyan-table-error-progress-font: #88141d; //异常进度条上方文字颜色

// cyan changelog
$cyan-changelog-timeline: #959595;
$cyan-changelog-year-size: 18px;
$cyan-changelog-year-font: #010101;
$cyan-changelog-time-size: 14px;
$cyan-changelog-itemtitle-size: 14px;
$cyan-changelog-year-background: #20b3f3;
$cyan-changelog-itemtitle-background: #416790;
$cyan-changelog-itemtitle-font: #ffffff;
$cyan-changelog-itemtitle-border: rgba(53, 83, 115, 0.3);
$cyan-changelog-content-background: #f0f4fb;
$cyan-changelog-content-font: #0d3c5d;

// orange changelog
$orange-changelog-timeline: #e5ddd0;
$orange-changelog-year-size: 18px;
$orange-changelog-year-font: #010101;
$orange-changelog-year-background: #F39c11;
$orange-changelog-time-size: 14px;
$orange-changelog-itemtitle-size: 16px;
$orange-changelog-itemtitle-background: #e5ddd0;
$orange-changelog-itemtitle-font: #000000;
$orange-changelog-itemtitle-border: rgba(53, 83, 115, 0.3);
$orange-changelog-content-background: #f9f8f7;
$orange-changelog-content-font: #000000;

$orange: #F39c11;
$grey-m1: #F4F4F4;
$grey: #E0E8E8;
$grey-1: #828A8D;
$grey-2: #4E565B;
$grey-3: #415462;
$grey-4: #2f4050;
$grey-5: #293846;
$blue-m1: #79baf4;
$blue: #718794;
$blue-0-1: #428bca;
$blue-1: #415462;
$blue-2: #344A5D;
$blue-3: #323D42;
$blue-4: #242c32;
$font-grey: #676767;
$font-black: #829CAC;
$cyan: #3c8dbc;

// basic states colors
$disabled: #9f9f9f;

$default: #ffffff;
$default-hover: #dbdbdb;
$default-active: #dbdbdb;

$primary: #0071d0;
$primary-hover: #1e76e2;
$primary-active: #0062b5;

$info: #20b3f3;
$info-hover: #2cbdfc;
$info-active: #0096d7;

$success: #1daf46;
$success-hover: #2fc058;
$success-active: #07972f;

$warning: #eda802;
$warning-hover: #feb405;
$warning-active: #e09e01;

$danger: #f25656;
$danger-hover: #f96767;
$danger-active: #dd3939;

$link: #0061e4;
$link-hover: #1fb3f3;
$link-active: #1fb3f3;

// form
$form-warning-color: #a94442;
$form-warning-border: 1px solid $form-warning-color;


// size
$left-nav-width: 220px;
$left-nav-item-height: 46px;
$left-second-nav-item-height: 48px;
$top-nav-height: 50px;

// border
$border-color: #ccc;
$border: 1px solid $border-color;

$cyan-border: #c7d1dd;
$orange-border: #dfdfdf;

//select
$cyan-select-label-bg: #e1e7ee;
$orange-select-label-bg: #f2f2f2;


//anchor-wrapper
$directory-circle-blue: #20b3f3;
$directory-line-blue: #416790;
$directtory-active: #e4eaf0;

$directory-circle-orange: #f69920;
$directory-line-orange: #f3e7d9;

$directory-circle-cyan: #20b3f3;
$directory-line-cyan: #416790;
$directtory-active: #e4eaf0;